/* cmd + shift + P   ->   Change language mode   ->   tailwindcss (to get rid of yellow underline) */

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  /* margin: 0; */
  overflow-x: hidden;
  height: 100%;
  @apply bg-gradient-to-br from-[#eae9e8] to-[#eae9e8];
}

body {
  /* margin: 0; */
  /* max-width: 1200px; */
  overscroll-behavior-y:none;
  overflow-x: hidden;
  height: 100%;
  line-height: 1.6;
  font-size: 18px;
  color: #444;
  padding: 0px;
  font-family: 'Helvetica', 'Arial', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  @apply text-zinc-500 underline
  /* color: #00a2e7; */
}

h1,h2,h3 {
  line-height: 1.2
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
